// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bus-faqs-js": () => import("./../../../src/pages/bus/faqs.js" /* webpackChunkName: "component---src-pages-bus-faqs-js" */),
  "component---src-pages-bus-index-js": () => import("./../../../src/pages/bus/index.js" /* webpackChunkName: "component---src-pages-bus-index-js" */),
  "component---src-pages-bus-terms-and-conditions-js": () => import("./../../../src/pages/bus/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-bus-terms-and-conditions-js" */),
  "component---src-pages-getting-to-school-education-transport-operators-js": () => import("./../../../src/pages/getting-to-school/education-transport-operators.js" /* webpackChunkName: "component---src-pages-getting-to-school-education-transport-operators-js" */),
  "component---src-pages-getting-to-school-js": () => import("./../../../src/pages/getting-to-school.js" /* webpackChunkName: "component---src-pages-getting-to-school-js" */),
  "component---src-pages-getting-to-school-prepare-js": () => import("./../../../src/pages/getting-to-school/prepare.js" /* webpackChunkName: "component---src-pages-getting-to-school-prepare-js" */),
  "component---src-pages-getting-to-school-routes-js": () => import("./../../../src/pages/getting-to-school/routes.js" /* webpackChunkName: "component---src-pages-getting-to-school-routes-js" */),
  "component---src-pages-getting-to-school-training-js": () => import("./../../../src/pages/getting-to-school/training.js" /* webpackChunkName: "component---src-pages-getting-to-school-training-js" */),
  "component---src-pages-getting-to-school-transport-js": () => import("./../../../src/pages/getting-to-school/transport.js" /* webpackChunkName: "component---src-pages-getting-to-school-transport-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-getting-to-school-page-js": () => import("./../../../src/templates/getting-to-school-page.js" /* webpackChunkName: "component---src-templates-getting-to-school-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-school-js": () => import("./../../../src/templates/school.js" /* webpackChunkName: "component---src-templates-school-js" */),
  "component---src-templates-town-or-city-js": () => import("./../../../src/templates/town-or-city.js" /* webpackChunkName: "component---src-templates-town-or-city-js" */)
}

